import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, NavItem, NavLink } from 'react-bootstrap';
import "./assets/css/App.css";
import "./assets/css/table.css"

//daily sales
import MultiStepForm from "./components/dailySales/MultiStepForm";
import dailyFuelSales from "./components/dailySales/stepForm/FuelSales";
import dailySaleDate from "./components/dailySales/stepForm/DailySaleDate";
import dailyLubeSales from "./components/dailySales/stepForm/LubeSales";
import dailyCollection from "./components/dailySales/stepForm/DailyCollection";
import dailySalesSummary from "./components/dailySales/stepForm/DailySalesSummary";
import dailyCreditSummary from "./components/dailySales/stepForm/DailyCreditSummary";
import dailyFuelDiscount from "./components/dailySales/stepForm/DailyFuelDiscount";

//daily sales info
import dailySalesInfo from "./components/dailySales/info/DailySaleDateInfo";

//info
import infoPage from "./components/common/InfoPage";
import lubeAddNewPriceInfoPage from "./components/common/LubeAddNewPriceInfoPage";

//daily stock
import DailyStockList from "./components/stock/DailyStockList";

//lube stocks
import AddLubricantStock from "./components/stock/AddLubricantStock";
import LubricantStockList from "./components/stock/LubricantStockList"
import EditLubricantStock from "./components/stock/EditLubricantStock";

//fuel stocks
import AddFuelStock from "./components/stock/AddFuelStock";
import FuelStockList from "./components/stock/FuelStockList";
import EditFuelStock from "./components/stock/EditFuelStock";

//credit entry
import AddCreditEntry from "./components/credit/AddCreditEntry";
import CreditBookEntryList from "./components/credit/CrediBookEntryList"
import EditCreditEntry from "./components/credit/EditCreditEntry";

//vehicle type
import AddVehicleType from "./components/vehicle/AddVehicleType";
import EditVehicleType from "./components/vehicle/EditVehicleType";
import VehicleTypeList from "./components/vehicle/VehicleTypeList";
//vehicle
import AddVehicle from "./components/vehicle/AddVehicle";
import EditVehicle from "./components/vehicle/EditVehicle";
import VehicleList from "./components/vehicle/VehicleList";
//daily fuel price
import EditDailyFuelPrice from "./components/dailySales/EditDailyFuelPrice";
import AddDailyFuelPrice from "./components/dailySales/AddDailyFuelPrice";
import DailyFuelPriceList from "./components/dailySales/DailyFuelPriceList";
//vehicle owner
import EditVehilceOwner from "./components/vehicle/EditVehicleOwner";
import VehicleOwnerList from "./components/vehicle/VehicleOwnerList";
import AddVehicleOwner from "./components/vehicle/AddVehicleOwner";

//credit transaction
import CreditTransactionList from "./components/credit/CreditTransactionList";
import AddCreditTransaction from "./components/credit/AddCreditTransaction";
import RecallCreditTransaction from "./components/credit/RecallCreditTransaction";

//report
import CreditOutstandingReport from "./components/reports/CreditOutstandingReport";
import FuelSalesReport from "./components/reports/FuelSalesReport";
import LubeSalesReport from "./components/reports/LubeSalesReport";
import CreditReceipt from "./components/reports/CreditReceipt";
import CreditBill from "./components/reports/CreditBill";
import ConsolidatedCreditReport from "./components/reports/ConsolidatedCreditReport";


//mail
import mailToAuditor from "./components/mail/MailToAuditor";

import Admin from "./Admin"
import ROHeader from "./components/common/ROHeader";
import Logout from "./components/access/Logout";
import { StylesProvider } from "@material-ui/core";
import Constants from "./Constants"
import { useEffect } from "react";
import FMLogo from "./components/common/FMLogo";


const FMApp = (props) => {

  const rediretToAmdin = () => {
    window.location.href = "/admin";
  }

  let user = JSON.parse(localStorage.getItem('authUser'));

  

  return (
      <div>
        <Navbar className='navbar'  variant="dark" expand="lg" sticky="top">
          <Navbar.Brand href="#home" className='p-0' style={{marginTop:'-12px'}}> <FMLogo height='30'/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Stocks" id="basic-nav-dropdown">
                <NavDropdown.Item href="/fmApp/fuelStockList">Fuel Stock</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/lubricantStockList">Lube Stock</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Credit Book" id="basic-nav-dropdown">
                <NavDropdown.Item href="/fmApp/vehicleOwnerList">Vehicle Owners</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/vehicleTypeList">Vehicle Types</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/vehicleList">Vehicles</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/fmApp/creditTransactionList">Credit Payments</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Daily Entries" id="basic-nav-dropdown">
                <NavDropdown.Item href={Constants.LIST_FUELPRICE}>Day Fuel Price</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/dailySalesList">Day Sales</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/dailyStockList">Day Stock</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/fmApp/creditBookEntryList">Day Credit</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Reports/Mail" id="basic-nav-dropdown">
                <NavDropdown.Item href="/fmApp/fuelSalesReport">Fuel Sales</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/lubeSalesReport">Lube Sales</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/fmApp/creditOustandingReport">Credit Outstanding</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/fmApp/mailToAuditor">Mail</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Receipt/Bill" id="basic-nav-dropdown">
                <NavDropdown.Item href="/fmApp/generateReceipt">Receipt</NavDropdown.Item>
                <NavDropdown.Item href="/fmApp/generateCreditBill">Credit Bill</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/fmApp/generateConsolidatedCreditBill">Consolidated Bill</NavDropdown.Item>
              </NavDropdown>
              <div><Logout className="float-left" /></div>
            </Nav>
          {/* show admin access link only if user role is as admin enabled*/}
            {user?.userRoleMO?.isAdmin === 1 && <div> <StylesProvider injectFirst>  <Link className="adminLink" onClick={rediretToAmdin} to="#">Go to admin</Link></StylesProvider></div>}
            <div> <ROHeader></ROHeader></div>
          </Navbar.Collapse>
        </Navbar>

        <div className="container mt-3">
          <Switch>
            //admin
            <Route exact path={"/admin"} component={Admin} />
            //fuelprice//
            <Route exact path={"/fmApp/fuelPriceList"} component={DailyFuelPriceList} />
            <Route exact path="/fmApp/addDailyFuelPrice" component={AddDailyFuelPrice} />
            <Route path="/fmApp/fuelPrice/:id" component={EditDailyFuelPrice} />
            //vehicle owner//
            <Route exact path={"/fmApp/vehicleOwnerList"} component={VehicleOwnerList} />
            <Route exact path="/fmApp/addVehicleOwner" component={AddVehicleOwner} />
            <Route path="/fmApp/vehicleOwner/:id" component={EditVehilceOwner} />
            //vehicle type//
            <Route exact path={"/fmApp/vehicleTypeList"} component={VehicleTypeList} />
            <Route exact path="/fmApp/addVehicleType" component={AddVehicleType} />
            <Route path="/fmApp/vehicleType/:id" component={EditVehicleType} />
            //vehicle//dailyFuelSales
            <Route exact path={"/fmApp/vehicleList"} component={VehicleList} />
            <Route exact path="/fmApp/addVehicle" component={AddVehicle} />
            <Route path="/fmApp/vehicle/:id" component={EditVehicle} />
            //daily sales//
            <Route exact path={"/fmApp/dailySalesList"} component={MultiStepForm} />
            <Route exact path={"/fmApp/fuelComponent"} component={dailyFuelSales} />
            <Route exact path={"/fmApp/dailySaleDateDetails"} component={dailySaleDate} />
            <Route exact path={"/fmApp/dailyLubeSales"} component={dailyLubeSales} />
            <Route exact path={"/fmApp/dailyCollection"} component={dailyCollection} />
            <Route exact path={"/fmApp/dailySalesSummary"} component={dailySalesSummary} />
            <Route exact path={"/fmApp/dailyCreditSummary"} component={dailyCreditSummary} />
            <Route exact path={"/fmApp/dailyFuelDiscount"} component={dailyFuelDiscount} />

            //daily sales info
            <Route exact path={"/fmApp/dailySalesInfo"} component={dailySalesInfo} />

            //daily stock//
            <Route exact path={"/fmApp/dailyStockList"} component={DailyStockList} />
            //fuel stocks//
            <Route exact path={"/fmApp/addFuelStock"} component={AddFuelStock} />
            <Route exact path={"/fmApp/fuel_stock/:id"} component={EditFuelStock} />
            <Route exact path={"/fmApp/fuelStockList"} component={FuelStockList} />

            //lube stocks//
            <Route exact path={"/fmApp/addLubricantStock"} component={AddLubricantStock} />
            <Route exact path={"/fmApp/lube_stock/:id"} component={EditLubricantStock} />
            <Route exact path={"/fmApp/lubricantStockList"} component={LubricantStockList} />


            //credit book entry//
            <Route exact path="/fmApp/addCreditEntry" component={AddCreditEntry} />
            <Route exact path="/fmApp/creditEntry/:id" component={EditCreditEntry} />
            <Route exact path={"/fmApp/creditBookEntryList"} component={CreditBookEntryList} />
            //credit transaction entry//
            <Route exact path={"/fmApp/creditTransactionList"} component={CreditTransactionList} />
            <Route exact path={"/fmApp/addCreditTransaction"} component={AddCreditTransaction} />
            <Route exact path={"/fmApp/recallCreditTransaction/:id"} component={RecallCreditTransaction} />

            //reports //
            <Route exact path={"/fmApp/creditOustandingReport"} component={CreditOutstandingReport} />
            <Route exact path={"/fmApp/fuelSalesReport"} component={FuelSalesReport} />
            <Route exact path={"/fmApp/lubeSalesReport"} component={LubeSalesReport} />
            <Route exact path={"/fmApp/generateReceipt"} component={CreditReceipt} />
            <Route exact path={"/fmApp/generateCreditBill"} component={CreditBill} />
            <Route exact path={"/fmApp/generateConsolidatedCreditBill"} component={ConsolidatedCreditReport} />

            //mail //
            <Route exact path={"/fmApp/mailToAuditor"} component={mailToAuditor} />
            //info //
            <Route exact path={"/fmApp/infoPage"} component={infoPage} />
            <Route exact path={"/fmApp/lubeAddNewPriceInfoPage"} component={lubeAddNewPriceInfoPage} />
          </Switch>
        </div>
      </div>
  );
}

export default FMApp;
