import React, { useState, useRef, useEffect } from "react";
import '../../assets/css/login.css';
import UserService from '../../services/admin/UserService'
import Model from "../common/editableTable/Model";
import PasswordBox from "../common/PasswordBox";
import Constants from "../../Constants";
import useAuth from "../../auth/useAuth";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import { Link } from "@material-ui/core";
import { Button } from "react-bootstrap";

import fmlogo from '../../assets/logo/fm-logo.png';

const CustomerLogin = (props) => {
    const [submitted, setSubmitted] = useState(false);
    /**model attributes*/
    const [message, setMessage] = useState("");
    const [modelVariant, setModelVariant] = useState();
    const autoRef = useRef(null);
    /**model attributes*/

    const userMO = {
        userName: '',
        userPassword: ''
    }
    const axiosPrivate = useAxiosPrivate();
    const [appUser, setAppUser] = useState(userMO)
    const { appUserCtx, setAppUserCtx } = useAuth();
    const [productUser, setProductUser] = useState();
    const [customerLogin, setCustomerLogin] = useState("");
    const [signInBtnTxt, setSignInTxt] = useState("");
    const [isCustomerDetailsLoading, setIsCustomerDetailsLoading] = useState(false);
    const error = {
        userNameError: '',
        userPasswordError: ''
    }
    const [appUserError, setAppUserError] = useState(error);

    useEffect(() => {
        console.log('customer id is -----------' + props.match.params.id);
        retreiveCustomerDetails(props.match.params.id)
    }, [props.match.params.id])

    const retreiveCustomerDetails = (id) => {
        localStorage.removeItem('authUser');
        setSignInTxt("Sign in");
        setSubmitted(true);
        setIsCustomerDetailsLoading(true);
        setCustomerLogin({ ...customerLogin, message: "please wait.. we are loading customer details" });
        UserService.getCustomerDetails(id)
            .then(response => {
                console.log("product user name is -----------" + response.data.name);
                setProductUser(response.data.productUserMO);
                setIsCustomerDetailsLoading(false);
                setCustomerLogin(response.data);
                setSubmitted(false);
            })
            .catch(e => {
                localStorage.setItem('errorMsg', e.response.data);
                window.location.href = Constants.ERROR_PAGE;
            })
    }

    const handleInputChange = event => {
        console.log(event);
        const { name, value } = event.target;
        setAppUser({ ...appUser, [name]: value });
    };

    const handleValidation = () => {
        if (!appUser?.userName) {
            setAppUserError({ ...appUserError, "userNameError": "please enter user name" });
            return false;
        } else if (!appUser?.userPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userPasswordError": "please enter password" });
            return false;
        }
        setAppUserError({ ...appUserError, "userNameError": '', "userPasswordError": "" });
        return true;
    }

    const verifyLogin = () => {
        if (handleValidation()) {
            //remove user incase if user closes the browser without logging out
            setSubmitted(true);
            setSignInTxt("Signing... in");
            localStorage.removeItem('authUser');
            appUser.productUserMO = productUser;
            UserService.verifyLogin(appUser).
                then(response => {
                    localStorage.setItem('authUser', JSON.stringify(response.data));
                    setAppUser(response.data);
                    setAppUserCtx(response.data);
                    setSubmitted(false);
                    setSignInTxt("Sign in");
                    props.history.push({ pathname: "/fmApp/dailySalesList" });
                }).catch(e => {
                    setSubmitted(false);
                    setSignInTxt("Sign in");
                    setModelVariant('error')
                    setMessage(e.response.data);
                    autoRef.current.setModelOpen(true);
                })
        }
    }

    const style = {
        marginLeft: '0%',
        marginTop: '-2%',
        wordWrap: 'break-word',
        width: 'inherit'
    }


    return (
        <>
            <section className="backstyle">
                <div className="logoComp col-md-12 col-lg-12 col-xl-12">

                    <div className="col-md-3">
                        <span ><img src={fmlogo} style={{ width: '250px', height: '80px' }}></img></span>
                    </div>
                </div>
                <div className="container-fluid h-custom mt-0" >
                    <div className="row d-flex justify-content-center align-items-center" >

                        <div style={{ marginLeft: '0px' }} className=" col-md-12 col-lg-12 col-xl-5 offset-xl-1 mt-0" >
                            <ul style={{marginLeft:'0px', paddingLeft:'0px'}}>
                                <p><span style={{ color: '#359C57' }}>Simple and powerful interface</span> for day to day activities of petrol pump</p>
                                <p><span style={{ color: '#C66463' }}>Single-flow approach</span> to complete daily sales in one stretch, instead of having different modules for various computation</p>
                                <p><span style={{ color: '#E0F5B2' }}>Users with minimal software/computer knowledge</span> can operate this app with ease; in less time</p>
                                <p>Designed in such a way, that <span style={{ color: '#794C8F' }}>users can finish daily sales in less than 5 mins</span> and without any module navigation to consolidate total sales</p>
                            </ul>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-4 offset-xl-1" style={{ marginTop: '80px' }}>
                            <div className="col-md-12" >
                                <div className={isCustomerDetailsLoading ? 'customerDetailsLoadingMessage' : 'customerDetailsMessage'}>
                                    {customerLogin && <label><span>{customerLogin.message}</span> </label>}
                                </div>
                                <div className='customerDetails'>
                                    {productUser && <label><h4 style={{ color: '#f1ed22' }}>Hi {productUser.name}</h4> </label>}
                                </div>
                            </div>
                            <div className="loginComp">


                                <form>
                                    <div className="mt-1">
                                        <div className="col-md-12 m-auto"> </div>
                                        <div className="col-md-12" style={{ marginLeft: '8rem' }}><h2>Login</h2></div>

                                    </div>
                                    <div>
                                        <Model style={style} message={message} ref={autoRef} hideDuration={6000} variant={modelVariant}></Model>
                                    </div>

                                    <div className="form-outline mb-4" style={{marginTop:"2rem"}}>
                                        <label> User Name <span className='errorMsg'>  {appUserError.userNameError}</span></label>
                                        <input type='text' autocomplete='off' value={appUser.userName} name='userName' id='userName' onChange={(e) => handleInputChange(e)}></input>
                                    </div>

                                    <div className="form-outline mb-3">
                                        <label> Password <span className='errorMsg'>  {appUserError.userPasswordError}</span></label>
                                        <PasswordBox name='userPassword' id='userPassword' value={appUser.userPassword} onChange={(e) => handleInputChange(e)} />
                                    </div>

                                    <div><Link underline="none" href="/fmApp/forgotpassword">Forgot password?</Link></div>
                                    <div>
                                        <Button name='signinBtn' id='signinBtn' onClick={(e) => verifyLogin()} disabled={submitted}>
                                            {signInBtnTxt}</Button>
                                    </div>

                                </form></div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )

}
export default CustomerLogin;